*::selection {
	color: white;
	background-color: #333;
}

.h,
h1, h2, h3, h4, h5, h6 {
	font-family: campton-medium, sans-serif;
	font-weight: 500;
	color: #333 !important;
}

.h1 {
	font-size: 35px;
}

.h2 {
	font-size: 29px;
}

.h3 {
	font-size: 24px;
}

p {
	color: black;
}

.title {
	font-family: campton-medium, sans-serif;
}

.wp-block-column {
	h1, h2, h3, h4, h5, h6 {
		font-weight: 500;

		strong {
			font-weight: initial;
		}
	}
}