#site-header-top {
	position: relative;
	display: flex;
	border-bottom: 1px solid #eaeaea;
	background-color: white;
	z-index: 4;

	@media (max-width: 992px) {
		display: none;
	}

	> .container-fluid {
		max-width: 1600px;

		> .row {
			display: flex !important;
		}
	}
	
	.left,
	.right {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.right {
		justify-content: flex-end;
		padding: 15px 0;

		li {
			display: block;
		}
	}

	p, a {
		margin: 0 15px;
		color: #8f8f8f;
		font-weight: 500;
		font-size: 14px;
	}
}

.admin-bar #site-header-bottom {
	top: 32px;
}

#site-header-bottom {
	position: sticky;
	top: 0;
	background-color: white;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	z-index: 3;

	> .container-fluid {
		max-width: 1600px;
	}

	.col-lg-12 {
		display: flex;
		height: 122px;
	}

	.logo {
		display: block;
		height: 100%;
		padding: 22px 18px 22px 0;

		@media (max-width: 575px) {
			padding-right: 0;
		}

		> img {
			height: 100%;
			width: auto;

			@media (max-width: 575px) {
				max-width: 175px;
				object-fit: contain;
			}
		}
	}

	.tabs {
		display: flex;
		margin-left: auto;

		@media (max-width: 992px) {
			max-height: calc(100vh - 120px);
			overflow: auto;
		}

		@media (max-width: 992px) {
			display: none;
			position: absolute;
			top: 122px; left: 0; right: 0;
			width: 100%;
			padding: 20px;
			background: white !important;
			background-color: white !important;
			z-index: 9 !important;
		}

		&.visible {
			display: block;
		}

		.nav_main_item {
			position: relative;
			display: block;

			@media (max-width: 992px) {
				margin: 15px 0;
				padding-right: 0 !important;
				padding-left: 10px !important;
			}

			&:hover > .sub_menu_wrapper {
				@media (min-width: 993px) {
					opacity: 1;
					visibility: visible;
					transform: translateY(0) translateX(-50%);
				}
			}

			&:hover > .dropdown_icon > svg {
				@media (min-width: 993px) {
					transform: rotateX(180deg);
				}
			}

			&.has_dropdown {
				padding-right: 34px;
			}

			&.mobile {
				display: none;

				@media (max-width: 992px) {
					display: block;
				}
			}

			> a {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 0 10px;
				color: black;
				letter-spacing: 0.5px;
				font-family: campton-medium, sans-serif;
				font-size: 15px;
				text-align: center;

				@media (max-width: 992px) {
					text-align: left;
				}

				&:hover {
					color: rgba(12, 180, 206, 0.5);
					text-decoration: none;
				}
			}

			.dropdown_icon {
				position: absolute;
				height: 15px;
				width: 50px;
				top: -16px; bottom: 0; right: 0; left: auto;
				margin: auto;
				cursor: pointer;

				@media (max-width: 992px) {
					right: auto;
					left: -22px;
					bottom: auto;
					top: -5px;
				}

				&.mobile_expanded > svg {
					@media (max-width: 992px) {
						transform: rotateX(180deg);
					}
				}

				> svg {
					height: 100%;
					width: 100%;
					transition: transform 0.32s;
				}
			}

			.sub_menu_wrapper {
				position: absolute;
				width: 280px;
				left: 50%;
				padding-top: 12px;
				transform: translateY(8px) translateX(-50%);
				opacity: 0;
				visibility: hidden;
				transition: visibility 0.33s, opacity 0.33s, transform 0.33s;

				@media (min-width: 993px) {
					display: block !important;
				}

				@media (max-width: 992px) {
					position: relative;
					width: 100%;
					left: unset;
					display: none;
					padding-top: 0;
					opacity: 1;
					visibility: visible;
					transform: none;
				}

				> .arrow {
					display: flex;
					justify-content: center;
					position: absolute;
					left: 0; top: 0;
					width: 100%;
					height: 20px;

					> svg {
						height: auto;
						width: 25px;
					}
				}

				.sub_menu {
					padding: 30px;
					background-color: white;
					border-radius: 5px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

					@media (max-width: 992px) {
						box-shadow: none;
						border-radius: 0;
						padding: 20px 0 20px 30px;
						margin: 0;
					}
				}

				.menu_item {
					display: block;
					margin: 20px 0;

					&:first-of-type {
						margin-top: 0;
					}

					&:last-of-type {
						margin-bottom: 0;
					}

					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						color: black;
						line-height: 1.2;
						font-family: campton-medium, sans-serif;

						&:hover {
							color: rgba(12, 180, 206, 0.5);
							text-decoration: none;
						}

						span:last-of-type {
							margin-left: 15px;
						}
					}
				}
			}
		}

		.search-form-container {
			display: none;

			@media (max-width: 992px) {
				display: block;
			}

			form > div {
				display: flex;
				gap: 10px;
			}

			input[type="text"] {
				width: calc(100% - 70px);
			}
		}
	}

	.icons {
		display: flex;
		align-items: center;
		padding-right: 10px;

		@media (max-width: 992px) {
			margin-left: auto;
		}

		button {
			margin: 0 15px;
			background: none;
			border: none;

			&:hover {
				color: rgba(12, 180, 206, 0.5);
			}

			&:focus {
				outline: none;
			}
		}

		#open-search-bar {
			@media (max-width: 992px) {
				display: none;
			}
		}

		.shopping-cart-container i:hover {
			color: rgba(12, 180, 206, 0.5);
		}

		#mobile-menu {
			display: none;

			@media (max-width: 992px) {
				display: block;
			}
		}
	}
}

#search-bar-container {
	position: fixed;
	top: 0; bottom: 0; left: 0; right: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 4;
	opacity: 0;
	pointer-events: none;

	&.visible {
		opacity: 1;
		pointer-events: auto;
	}

	&.add-transition {
		transition: opacity 0.33s;
	}

	.background {
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	#search-bar {
		position: relative;
		padding: 20px;
		border: 1px solid black;

		@media (max-width: 992px) {
			width: calc(100% - 60px);
		}

		input[type="text"] {
			width: 700px;
			color: white !important;
			background: none !important;
			border: none !important;
			font-size: 26px;

			@media (max-width: 992px) {
				width: calc(100% - 60px);
			}

			&::placeholder {
				color: white !important;
			}
		}

		button[type="submit"] {
			background: none;
			border: none;
			color: white;
			font-size: 40px;
		}
	}

	#search-bar-close {
		position: absolute;
		top: 15px; right: 15px;
		background: none;
		border: none;
		color: white;
		font-size: 22px;
	}
}

.shopping-cart-container {
	@media (max-width: 992px) {
		position: static;
	}

	&.visible {
		.shopping-cart-sidebar {
			opacity: 1;
			pointer-events: auto;
		}
	}

	i {
		font-size: 1.2rem;
		text-align: center;
		cursor: pointer;
		transition: background-color 0.3s;
	}

	.shopping-cart-sidebar {
		position: absolute;
		top: 122px; right: 0;
		width: 400px;
		background-color: white;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		opacity: 0;
		z-index: 2;
		pointer-events: none;

		@media (max-width: 992px) {
			width: 100%;
			left: 0; right: 0;
		}

		&.add-transition {
			transition: opacity 0.3s;
		}

		.woocommerce-mini-cart__empty-message {
			margin-top: 1rem;
			text-align: center;
		}

		.widgettitle {
			margin: 0;
			padding: 0.5rem 1rem;
			text-align: center;
			font-size: 1.5rem;
			font-weight: bold;
			color: white;
			background-color: #4dc78d;
		}

		.woocommerce-mini-cart.cart_list.product_list_widget {
			max-height: calc(100vh - 500px);
			overflow-y: scroll;

			@media (max-width: 768px) {
				max-height: calc(100vh - 150px);
			}
		}

		.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
			display: flex;
			margin: 0;
			padding: 1rem 1rem 1rem 2rem;
			border-bottom: 1px solid lightgray;

			&:last-of-type {
				border-bottom: none;
			}

			.quantity {
				display: block;
				margin: 10px 0 0 0;
			}

			a:last-of-type {
				display: flex;
				justify-content: space-between;
				flex-flow: row-reverse;
				align-items: flex-start;
				height: auto;
				line-height: 20px;
				color: #333;

				img {
					width: 50px;
				}
			}

			a.remove {
				top: 11px; left: unset; right: 15px;
				padding: 0;

				&:hover {
					color: red !important;
					background-color: white;
				}
			}
		}

		.woocommerce-mini-cart__total.total {
			background: #f7f7f7;
			margin: 0 !important;
			padding: 1rem;
			border-top: 1px solid lightgray;
			border-bottom: 1px solid lightgray;
		}

		.woocommerce-mini-cart__total.total {
			margin: 1rem;
			padding-top: 1rem;
		}

		.woocommerce-mini-cart__buttons.buttons {
			margin: 1rem;
		}

		.attachment-woocommerce_thumbnail.size-woocommerce_thumbnail {
			height: 100%;
			width: 60px;
			object-fit: contain;
		}

		.product-info {
			margin-left: 15px;

			.product-name {
				margin-bottom: 0;
				font-weight: bold;
			}
		}
	}
}

.site_header_banner {
	padding: 10px 20px;
	color: #d1ca00;
	background-color: black;
	text-align: center;
	font-weight: 700;
	font-size: 20px;

	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

body.admin-bar #site-header-bottom .tabs {
	@media (max-width: 992px) {
		max-height: calc(100vh - 154px);
	}
}