@import "./partials/text.less";
@import "./partials/site_header.less";

#site-header-top {
	padding-top: 0;
}

#search-bar-container {
	margin-top: 0;
}

.container {
	max-width: 1200px;
}

label {
	font-weight: bold;
}

input,
textarea {
	padding: 10px 15px !important;
	border: 1px solid #eaeaea !important;
	border-radius: 4px;
	box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.025);

	&:focus {
		border: 1px solid black;
		outline: none;
	}
}

input[type="submit"] {
	background-color: #eaeaea;
}

input[type="number"] {
	width: auto !important;
	max-width: 90px;
	padding-right: 0 !important;
}

.sidebar {
	.widget {
		margin-bottom: 40px;
	}

	.widget-title {
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid lightgray;
		font-family: campton-medium, sans-serif;
	}

	.price_slider.ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
		background-color: #eaeaea !important;
	}

	.ui-slider-handle.ui-state-default.ui-corner-all,
	.ui-slider-range.ui-widget-header.ui-corner-all {
		background-color: black !important;
	}

	.price_slider_amount {
		button {
			color: white !important;
			background-color: black !important;
		}
	}

	.price_label {
		color: #777 !important;
		font-weight: bold !important;
	}

	ul {
		padding: 0;

		li {
			display: block;
			margin-bottom: 8px;

			&:before {
				content: "\f105";
				margin-right: 10px;
				font-family: "Font Awesome 5 Free Solid";
			}

			a {
				color: black;
				font-weight: 600;
				font-size: 15px;
			}
		}
	}
}

.tease-product {
	margin-bottom: 30px;
	text-align: center;

	.img-container:hover {
		text-decoration: none;
	}

	.img-container:hover > .img > .overlay {
		opacity: 1;
	}

	.img-container:hover > .button,
	.img-container:hover > .added_to_cart {
		bottom: 0;
	}

	.img-container {
		position: relative;
		overflow: hidden;

		.img {
			position: relative;
			display: block;

			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}

			.overlay {
				position: absolute;
				top: 0; bottom: 0; left: 0; right: 0;
				height: 100%;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.3);
				opacity: 0;
				transition: opacity 0.16s;
			}
		}

		.button,
		.added_to_cart {
			position: absolute;
			bottom: -40px; left: 0; right: 0;
			height: 40px;
			line-height: 40px;
			margin: 0 !important;
			padding: 0 !important;
			color: white;
			background-color: rgba(0, 0, 0, 0.8) !important;
			text-align: center;
			font-weight: 700;
			border-radius: 0 !important;
			border: none !important;
			transition: bottom 0.16s;
			z-index: 1;

			&.added {
				display: none;
			}

			&:hover {
				color: white !important;
				background-color: rgba(0, 0, 0, 0.8) !important;
				text-decoration: none;
			}

			&.loading:after {
				top: 0 !important;
			}
		}
	}

	.entry-title {
		margin: 15px 0 0;
		font-family: campton-medium, sans-serif;
	}

	.price {
		display: block;
		font-weight: 700;
	}

	.product-data {
		p {
			margin: 0;
			color: #777;
		}
	}

	.soldout {
		position: absolute;
		top: 10px; left: 10px;
		pointer-events: none;
		border-radius: 2px;
		font-size: 11px !important;
		padding: 2px 5px;
		display: inline-block;
		font-weight: 600;
		line-height: 1.5;
		text-transform: uppercase;
		text-align: center;
		color: #fff !important;
		background-color: #f73b3b;
	}

	.title_wrapper {
		* {
			color: black;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

body .woocommerce-cart-form {
	a.remove {
		color: black !important;

		&:hover {
			color: black !important;
			background: none !important;
		}
	}

	.product-thumbnail img {
		width: 80px !important;
		margin: 10px 0;
	}

	.product-name a {
		color: black;

		&:hover {
			text-decoration: none;
		}
	}

	input[name="coupon_code"] {
		width: 120px !important;
	}

	.button {
		float: left;
		height: 48px;
		line-height: 42px !important;
		margin-left: 18px !important;
		padding: 0 30px !important;
		color: white !important;
		background-color: black !important;
		border: 1px solid black !important;
		transition: color 0.16s, background-color 0.16s;

		&:hover {
			color: black !important;
			background-color: white !important;
		}
	}
}

.checkout-button.button.alt.wc-forward {
	color: white;
	background-color: black;
	border: 1px solid black !important;
	transition: color 0.16s, background-color 0.16s;

	&:hover {
		color: black !important;
		background-color: white !important;
	}
}

.woocommerce-info {
	background: none !important;
	border-top: none !important;
    border-bottom: 3px solid #1e85be;
}

#scroll-to-top {
	position: fixed;
	bottom: -100px; right: 15px;
	height: 40px;
	width: 40px;
	color: white;
	background-color: black;
	border: none;
	outline: none;
	transition: bottom 0.16s;

	&.visible {
		bottom: 15px;
	}

	i {
		height: 40px;
		line-height: 40px;
		width: 100%;
		margin: 0 !important;
		text-align: center;
	}
}

html body a:where(:not(.wp-element-button)) {
	text-decoration: none;
}

div#custom_fancy_box {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
div#custom_fancy_box .overlay {
	opacity: 1;
	visibility: visible;
	background: #000000a1;
	z-index: 9;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
div#custom_fancy_box .img_cont {
	z-index: 91;
	position: relative;
	max-width: 800px;
	width: 90%;
}
div#custom_fancy_box .img_cont img {
	max-width: 100%;
}
div#custom_fancy_box span.close-btn {
	position: absolute;
	z-index: 993;
	top: 2%;
	right: 2%;
	background: #000;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 100%;
	font-size: 18px;
	cursor: pointer;
}